// Here you can add other styles

.custom-coreui-sidebar {
  background: #0a1932;
}

.custom-coreui-login-card {
  background-color: #0a1932 !important;
}

.container-home {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.color-input {
  border-radius: 100%;
  height: 30px;
  width: 30px;
  border: none;
  padding: 0;
  outline: none;
  -webkit-appearance: none;

  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }

  &::-webkit-color-swatch {
    border: none;
    border-radius: 100%;
  }
}

.error-form {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #e55353;
}

.button-add {
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  padding: 7px 10px;

  svg {
    margin: 0 5px 0 0 !important;
    width: 15px !important;
    height: 15px !important;
  }

  &::after {
    margin-left: 5px !important;
  }
}

.badge {
  line-height: 1.1 !important;
}

.container-simlulate {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}
